import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import FaqLeftNav from "../../../../components/TwoColumnLeftNav/FaqLeftNav";
import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";

const PhMeters = ({ data: { allMdx } }) => {
	const { phMetersAbout } = useTranslations();
	return (
		<>
			<article className="category-page two-column-left-nav">
				<FaqLeftNav />
				<section className="main-content">
					<section className="main-content">
						<div className="content-container">
							<h1 className={"large bold"}>{phMetersAbout} </h1>

							<ul className="post-list between wrap">
								{allMdx.edges.map(({ node: post, index }) => (
									<li
										className={"faq-list"}
										key={`${post.frontmatter.title}-${post.fields.locale}`}
									>
										<h2 className="faq-title">Q.{post.frontmatter.title}</h2>
										<MDXRenderer>{post.body}</MDXRenderer>
									</li>
								))}
							</ul>
						</div>
					</section>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default PhMeters;

export const query = graphql`
	query phMetersFaq($locale: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(faq)/.*\\\\.mdx$/" }
				frontmatter: { categories: { regex: "/(ph-meters)/" } }
				fields: { locale: { eq: $locale } }
			},
			sort: {fields: frontmatter___sort}
		) {
			edges {
				node {
					frontmatter {
						title
					}
					body
					fields {
						locale
					}
				}
			}
		}
	}
`;
